import { AppBar, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogTitle, Divider, Grid, IconButton, InputAdornment, List, ListItemButton, ListItemText, Slide, Toolbar, Typography } from "@mui/material";
import React from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ItemsModel = ({ openItems, closeItmes }) => {
  const returnImage = (itemName) => {
    let imgUrl = "";
    const findDesignImage = openItems.design.find((design) => design.productName === itemName.split(" -")[0]);
    if (findDesignImage) {
      imgUrl = findDesignImage.image[0];
    }
    return (
      <Grid m={0.4}>
        <img src={imgUrl} alt="Shirt" style={{ width: "100px", height: "120px" }} />
      </Grid>
    );
  };
  return (
    <React.Fragment>
      {/* <Button variant="outlined">Open full-screen dialog</Button> */}
      <Dialog fullScreen open={openItems.state} onClick={() => {}} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Items
            </Typography>
            <Button autoFocus color="inherit" onClick={closeItmes}>
              Cancel
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          {openItems.items.map((items, index) => (
            <Grid mt={2} mb={1} m={1.5}>
              <Card key={index} borderRadius={3} boxShadow={3} sx={{ marginTop: "5px" }}>
                <Box m={0.1} borderRadius={1} boxShadow={3}>
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Grid>{returnImage(items.itemName)}</Grid>
                    <Grid>
                      <Grid>
                        <Typography color={"blue"}>{items.itemName}</Typography>
                      </Grid>
                      <Grid display={"flex"} justifyContent={"space-between"}>
                        <Grid>
                          <Grid>
                            <Typography fontSize={13}>SKU</Typography>
                          </Grid>
                          <Grid>
                            <Typography sx={{ typography: "subtitle2" }}>{items.sku}</Typography>
                          </Grid>
                        </Grid>
                        <Grid mr={2}>
                          <Grid>
                            <Typography fontSize={13}>Quantity</Typography>
                          </Grid>
                          <Grid>
                            <Typography sx={{ typography: "subtitle2" }}>{items.sku}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid mt={1} mb={1} display={"flex"}>
                        {items.rackCollection && items.rackCollection.length > 0 ? (
                          items.rackCollection.map((rack, index) => (
                            <>
                              <Grid key={index} style={{ whiteSpace: "nowrap" }} mr={1}>
                                {rack.floorNumber && rack.floorNumber} : {rack.rackNumber && rack.rackNumber}
                              </Grid>
                            </>
                          ))
                        ) : (
                          <Typography justifyContent={"center"}> Racks Not Allocated</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          ))}
          <Divider />
        </List>
      </Dialog>
    </React.Fragment>
  );
};

export default ItemsModel;
