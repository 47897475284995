import { Box, Button, Card, Checkbox, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ItemsModel from "./ItmesModel";
import ErrorModal from "./ErrorModel";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const { REACT_APP_SERVER_URL } = process.env;

// const token = sessionStorage.getItem("access_token");

const PendingOrderList = () => {
  const navigation = useNavigate();
  const redirectUrl = "/";


  const [orderList, setOrderList] = useState([]);
  const [openItemsModel, setItemsModel] = useState({
    state: false,
    items: [],
    design: [],
  });
  const [loading, setLoading] = useState(false);

  const [errorStatus, setErrorStatus] = useState({
    state: false,
    error: "",
  });
  const [racks, setRacks] = useState([]);
  const [floors, setFloors] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState("All");
  const [selectedRack, setSelectedRack] = useState([]);

  const getAllRacksAndFloor = async () => {
    const token=getInitialUserDetailsFromSessionStorage();
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/employee/racks/v1/get-all-racks-with-floor`, {
        headers: {
          Authorization: `Bearer ${token.token}`,
        },
      });

      if (response.status === 200) {
        setFloors(response.data.rackList);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.message) {
          setErrorStatus({
            state: true,
            error: error.message,
          });
        } else {
          setErrorStatus({
            state: true,
            error: "Something Went Wrong",
          });
        }
      }
    }
  };

  const onChangeFloorsHandler = (e) => {
    setRacks([]);
    setSelectedRack([]);
    setSelectedFloor(e.target.value);
    const rackList = floors.find((floor) => floor._id === e.target.value);
    if (rackList) {
      setRacks(rackList.racks);
    }
  };

  const onChangeRackSelect = (e) => {
    setSelectedRack(e.target.value);
  };

  const onClickSearchItems = () => {
    pendingOrder(selectedFloor, selectedRack);
  };
  const getInitialUserDetailsFromSessionStorage = () => {
      const token = sessionStorage.getItem("access_token");
      if (token === null || token === undefined) {
        return navigation(redirectUrl);
    } else {
        return { token: token };
    }
};

  const pendingOrder = async (selectedFloors = "All", selectedRacks = []) => {
      setLoading(true);
      const token=  getInitialUserDetailsFromSessionStorage();
      if(token.token !== null){
      try {
       const response = await axios.post(
        `${REACT_APP_SERVER_URL}/employee/order/v1/pending-orders`,
        {
          selectedFloors: selectedFloors,
          selectedRacks: selectedRacks,
        },
        {
          headers: {
            Authorization: `Bearer ${token.token}`,
          },
        }
      );
      if (response && response.status && response.status === 200) {
        setOrderList(response.data.orderList);
      }
      if (response && response.status && response.status === 200) {
        setOrderList(response.data.orderList);
      }
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        if (error.message) {
          setErrorStatus({
            state: true,
            error: error.message,
          });
        } else {
          setErrorStatus({
            state: true,
            error: "Something Went Wrong",
          });
        }
      }
    } finally {
      setLoading(false);
    }
}

  };

  const onClickViewItems = (orderId) => {
    const findOrderItems = orderList.find((order) => orderId === order.orderId);
    if (findOrderItems && findOrderItems.items) {
      setItemsModel({
        items: findOrderItems.items,
        design: findOrderItems.designDetails,
        state: true,
      });
    }
  };

  const onclickMarkAsCompletedOrder = async (orderId) => {
    const token=getInitialUserDetailsFromSessionStorage();
    try {
      await axios.post(
        `${REACT_APP_SERVER_URL}/employee/order/v1/complete-order-by-employee`,
        { orderId },
        {
          headers: {
            Authorization: `Bearer ${token.token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        setErrorStatus({
          state: true,
          error: JSON.stringify(error),
        });
      } else {
        setErrorStatus({
          state: true,
          error: "Something Went Wrong",
        });
      }
    } finally {
      pendingOrder(selectedFloor, selectedRack);
    }
  };

  useEffect(() => {
    pendingOrder(selectedFloor, selectedRack);
    if (racks.length === 0) {
      getAllRacksAndFloor();    
    }
  }, []);

  const closeItems = () => {
    setItemsModel({ state: false, items: [], design: [] });
  };

  const isoutOfStock = (items, variants) => {
    for (let index = 0; index < items.length; index++) {
      const item = items[index].quantity;
      for (let index = 0; index < variants.length; index++) {
        const inventory = variants[index].inventory;
        if (item > inventory) {
          return (
            <Grid color={"red"} mt={0.5} mb={0.5}>
              <typography>Out of Stock</typography>
            </Grid>
          );
        }
      }
    }
  };

  return (
    <Grid style={{ height: "100vh", overflow: "hidden" }}>
      {/* <FilterComponent getItems={pendingOrder}  /> */}
      <Grid container my={1} mx={1} display={"flex"} justifyContent={"space-around"} maxWidth={"95vw"}>
        <Grid item md={5} sm={5} xs={5}>
          <FormControl size="small" fullWidth>
            <InputLabel size="small">Select Floor</InputLabel>
            <Select size="small" value={selectedFloor} label="Select Floor" onChange={onChangeFloorsHandler}>
              <MenuItem value={"All"} key={"All"}>
                All
              </MenuItem>
              {floors.map((floor) => (
                <MenuItem value={floor._id} key={floor._id}>
                  <ListItemText primary={floor._id} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid md={5} sm={5} xs={5}>
          <FormControl fullWidth>
            <InputLabel size="small">Select Racks</InputLabel>
            <Select multiple size="small" value={selectedRack} label="Select Racks" onChange={onChangeRackSelect} renderValue={(selected) => selected.join(", ")}>
              {racks.map((rack, index) => (
                <MenuItem key={index} value={rack.rackNumber}>
                  <Checkbox checked={selectedRack.indexOf(rack.rackNumber) > -1} />
                  <ListItemText primary={rack.rackNumber} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid display={"flex"} alignItems={"center"} md={1} sm={1} xs={1}>
          <IconButton onClick={onClickSearchItems} color="primary">
            <Search fontSize="medium" />
          </IconButton>
        </Grid>

        {errorStatus.state && <ErrorModal error={errorStatus.error} setErrorModal={setErrorStatus} severity={errorStatus.severity ?? "error"} />}
      </Grid>

      <Divider />
      {loading ? (
        <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} height={"50vh"}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid style={{ height: "calc(100vh - 80px)", overflow: "auto" }}>
          {orderList.length > 0 ? (
            <>
              {orderList.map((order, index) => (
                <Grid mt={2} mb={1} m={1.5}>
                  <Card key={index} borderRadius={3} boxShadow={3} sx={{ marginTop: "5px" }}>
                    <Box m={0.1} borderRadius={1} boxShadow={3}>
                      <Grid display={"flex"} justifyContent={"space-around"}>
                        <Grid>
                          <Grid mt={1}>
                            <Typography fontSize={10}>Order Id</Typography>
                          </Grid>

                          <Grid sx={{ typography: "subtitle2" }}>{order.orderId}</Grid>
                        </Grid>
                        <Grid>
                          <Grid mt={1}>
                            <Typography fontSize={10}>Packager Details</Typography>
                          </Grid>
                          <Grid sx={{ typography: "subtitle2" }}>{order.assignedPackager && order.assignedPackager.firstname && order.assignedPackager.firstname !== null && order.assignedPackager?.firstname}</Grid>
                        </Grid>
                      </Grid>
                      <Grid display={"flex"} justifyContent={"space-around"} mt={2}>
                        <Grid>
                          <Button
                            variant="contained"
                            onClick={() => {
                              onClickViewItems(order.orderId);
                            }}
                          >
                            <Grid display={"flex"} justifyContent={"space-between"} mr={1}>
                              <RemoveRedEyeIcon />
                            </Grid>
                            View Items
                          </Button>
                        </Grid>
                        <Grid>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              onclickMarkAsCompletedOrder(order.orderId);
                            }}
                          >
                            Mark Complete
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid container justifyContent={"space-between"} mt={1}>
                        <Grid item>{isoutOfStock(order.items, order.variantDetails)}</Grid>
                        <Grid item>
                          {/* <Typography sx={{ fontWeight: 400 }}>{new Date(order.createdDateTime).toLocaleString()}</Typography> */}

                          <Typography fontSize={13} mr={3}>
                            {new Date(order.createdDateTime).getDate()}-{new Date(order.createdDateTime).toLocaleString("default", { month: "long" })}-{new Date(order.createdDateTime).getFullYear()} {new Date(order.createdDateTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Typography color={"blue"} display={"flex"} justifyContent={"center"} mt={4}>
                No Pending Order Found...
              </Typography>
            </>
          )}
          {}
        </Grid>
      )}

      {openItemsModel.state && <ItemsModel openItems={openItemsModel} closeItmes={closeItems} />}
      {errorStatus.state && <ErrorModal error={errorStatus.error} setErrorModal={setErrorStatus} severity={errorStatus.severity ?? "error"} />}
    </Grid>
  );
};

export default PendingOrderList;
