import { Divider, Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import CompleteOrder from "./CompleteOrder";
import PendingOrderList from "./PendingOrder";

const HeaaderComponent = () => {
  const [redirectUrl, setRedirectUrl] = useState("/pending-order");
  const [currentTab, setCurrentTab] = useState(0);

  const onChangeTabHandler = (e) => {
    switch (e.target.textContent) {
      case "Pending":
        setRedirectUrl("/pending-order");
        setCurrentTab(0);
        break;

      case "Completed":
        setRedirectUrl("/complete-order");
        setCurrentTab(1);
        break;

      default:
        break;
    }
  };

  return (
    <Grid sx={{ overflowY: "hidden", maxHeight: "98vh" }}>
      <Grid justifyContent={"center"} display={"flex"} px={2} sx={{ height: "7vh" }}>
        <Tabs sx={{ width: "99vw" }} value={currentTab} onChange={onChangeTabHandler}>
          <Tab sx={{ width: "50%" }} label="Pending" />
          <Tab sx={{ width: "50%" }} label="Completed" />
        </Tabs>
      </Grid>
      <Divider />
      {redirectUrl === "/pending-order" && <PendingOrderList />}
      {redirectUrl === "/complete-order" && <CompleteOrder />}
    </Grid>
  );
};

export default HeaaderComponent;
