import { Box, Button, Card, CardActions, CardContent, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorModal from "./ErrorModel";
import { Tune } from "@mui/icons-material";

const { REACT_APP_SERVER_URL } = process.env;
const Login = () => {
  const navigation = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState({
    state: false,
    error: "",
  });

  const onLogin = async () => {
    const redirectUrl = "/order";
    if (phoneNumber === undefined || phoneNumber.toString().length !== 10) {
      return setErrorStatus({
        state: true,
        error: "please enter valid Mobile number.",
      });
    }
    if (password === "") {
      return setErrorStatus({
        state: true,
        error: "please enter valid Pin number.",
      });
    }
    setLoading(true);

    try {
      const response = await axios.post(`${REACT_APP_SERVER_URL}/employee/v1/login`, { phoneNumber: phoneNumber, pin: password });
      if (response && response.data && response.data.data.token && response.data.data.token) {
        sessionStorage.setItem("access_token", response.data.data.token);
        sessionStorage.setItem("url", "/pending-order");
        navigation(redirectUrl);
        setLoading(false);
      } else {
        setErrorStatus({
          state: true,
          error: "Something Went Wrong",
        });
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.status && error.response.data.status === "FAILED") {
        setErrorStatus({
          state: true,
          error: error.response.data.message,
        });
      } else {
        setErrorStatus({
          state: true,
          error: "Something Went Wrong",
        });
      }
      setLoading(false);
    }
  };
  const getInitialUserDetailsFromSessionStorage = () => {
    const token = sessionStorage.getItem("access_token");
    if (token === null || token === undefined) {
      return navigation("/");
    } else {
      return { token: token };
    }
  };
  useEffect(() => {
    const token = getInitialUserDetailsFromSessionStorage();
    if (token && token.token && token.token !== null) {
      navigation("order");
    } else {
      navigation("/");
    }
  }, []);
  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <Box m={1} mb={3} marginTop={"150px"} borderRadius={3} boxShadow={3}>
          <CardContent>
            <Grid display={"flex"} justifyContent={"center"}>
              <Typography sx={{ fontSize: 14 }} gutterBottom variant="h4">
                Punekar Cotton
              </Typography>
            </Grid>
          </CardContent>
          <Grid mt={1} m={1}>
            <TextField
              variant="outlined"
              label="UserName"
              type="text"
              fullWidth
              value={phoneNumber}
              m={1}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid mt={1} m={1}>
            <TextField
              variant="outlined"
              label="Password"
              type="password"
              fullWidth
              value={password}
              m={1}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Grid>

          <CardActions fullWidth>
            <Button fullWidth variant="contained" onClick={onLogin}>
              {loading ? (
                <Grid>
                  <CircularProgress size={25} color="inherit" />
                </Grid>
              ) : (
                "Login"
              )}
            </Button>
          </CardActions>
        </Box>
      </Card>

      {errorStatus.state && <ErrorModal error={errorStatus.error} setErrorModal={setErrorStatus} severity={errorStatus.severity ?? "error"} />}
    </>
  );
};

export default Login;
