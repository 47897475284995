import { Box, Button, Card, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ItemsModel from "./ItmesModel";
import FilterComponent from "./FilterComponent";
import ErrorModal from "./ErrorModel";
import { useNavigate } from "react-router-dom";
const { REACT_APP_SERVER_URL } = process.env;

// const token = sessionStorage.getItem("access_token");


const CompleteOrder = () => {
  const [orderList, setOrderList] = useState([]);
  const [openItemsModel, setItemsModel] = useState({
    state: false,
    items: [],
    design: [],
  });
  const navigation = useNavigate();
  const redirectUrl = "/";

  const getInitialUserDetailsFromSessionStorage = () => {
    const token = sessionStorage.getItem("access_token");
    if (token === null || token === undefined) {
      return navigation(redirectUrl);
  } else {
      return { token: token };
  }
};

  const [errorStatus, setErrorStatus] = useState({
    state: false,
    error: "",
  });

  const [loading, setLoading] = useState(false);

  const GetAllCompleteOrder = async (selectedFloors = "All", selectedRacks = []) => {
    const token= getInitialUserDetailsFromSessionStorage();
    if(token.token !== null){
        try {
            setLoading(true);
            const response = await axios.post(
              `${REACT_APP_SERVER_URL}/employee/order/v1/completed-orders`,
              {
                selectedFloors: selectedFloors,
                selectedRacks: selectedRacks,
              },
              {
                headers: {
                  Authorization: `Bearer ${token.token}`,
                },
              }
            );
            if (response && response.status && response.status === 200) {
              setOrderList(response.data.orderList);
            }
          } catch (error) {
            console.log(error);
            if (error instanceof AxiosError) {
              if (error.message) {
                setErrorStatus({
                  state: true,
                  error: error.message,
                });
              } else {
                setErrorStatus({
                  state: true,
                  error: "Something Went Wrong",
                });
              }
            }
          } finally {
            setLoading(false);
          }
    }
  };
  const onClickViewItems = (orderId) => {
    const findOrderItems = orderList.find((order) => orderId === order.orderId);
    if (findOrderItems && findOrderItems.items) {
      setItemsModel({
        items: findOrderItems.items,
        design: findOrderItems.designDetails,
        state: true,
      });
    }
  };

  const closeItems = () => {
    setItemsModel({ state: false, items: [], design: [] });
  };

  const onClickRevertOrder = async (orderId) => {
    const token= getInitialUserDetailsFromSessionStorage();
    try {
      await axios.post(
        `${REACT_APP_SERVER_URL}/employee/order/v1/reset-order-by-employee`,
        { orderId },
        {
          headers: {
            Authorization: `Bearer ${token.token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        setErrorStatus({
          state: true,
          error: JSON.stringify(error),
        });
      } else {
        setErrorStatus({
          state: true,
          error: "Something Went Wrong",
        });
      }
    } finally {
      GetAllCompleteOrder();
    }
  };

  useEffect(() => {
    GetAllCompleteOrder();
  }, []);

  return (
    <Grid style={{ height: "100vh", overflow: "hidden" }}>
      {loading ? (
        <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} height={"50vh"}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid style={{ height: "calc(100vh - 80px)", overflow: "auto" }} >
          {orderList.length > 0 ? (
            <>
              {orderList.map((order, index) => (
                <Grid mt={2} mb={1} m={1.5}>
                  <Card key={index} borderRadius={3} boxShadow={3} sx={{ marginTop: "5px" }}>
                    <Box m={0.1} borderRadius={1} boxShadow={3}>
                      <Grid display={"flex"} justifyContent={"space-around"}>
                        <Grid>
                          <Grid mt={1}>
                            <Typography fontSize={13}>Order Id</Typography>
                          </Grid>

                          <Grid sx={{ typography: "subtitle2" }}>{order.orderId}</Grid>
                        </Grid>
                        <Grid>
                          <Grid mt={1}>
                            <Typography fontSize={13}>Packager Details</Typography>
                          </Grid>
                          <Grid sx={{ typography: "subtitle2" }}>{order.assignedPackager.firstname && order.assignedPackager.firstname}</Grid>
                        </Grid>
                      </Grid>
                      <Grid display={"flex"} justifyContent={"space-around"} mt={2}>
                        <Grid>
                          <Button
                            variant="contained"
                            borderRadius={4}
                            onClick={() => {
                              onClickViewItems(order.orderId);
                            }}
                          >
                            <Grid display={"flex"} justifyContent={"space-between"} mr={1}>
                              <RemoveRedEyeIcon />
                            </Grid>
                            View Items
                          </Button>
                        </Grid>
                        <Grid>
                          <Button onClick={() => onClickRevertOrder(order.orderId)} variant="outlined">
                            Revert
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid container justifyContent={"end"} mt={1}>
                        <Grid item>
                          <Typography fontSize={13} mr={3}>
                            {new Date(order.createdDateTime).getDate()}-{new Date(order.createdDateTime).toLocaleString("default", { month: "long" })}-{new Date(order.createdDateTime).getFullYear()} {new Date(order.createdDateTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Typography color={"blue"} display={"flex"} justifyContent={"center"} mt={4}>
                No Complete Order Found...
              </Typography>
            </>
          )}
        </Grid>
      )}

      {openItemsModel.state && <ItemsModel openItems={openItemsModel} closeItmes={closeItems} />}
      {errorStatus.state && <ErrorModal error={errorStatus.error} setErrorModal={setErrorStatus} severity={errorStatus.severity ?? "error"} />}
    </Grid>
  );
};

export default CompleteOrder;
