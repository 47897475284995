// import Login from "./Login";
import CompleteOrder from "./component/CompleteOrder";
import HeaaderComponent from "./component/Header";
import Login from "./component/Login";
import PendingOrder from "./component/PendingOrder";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="container-fluid">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/pending-order" element={<PendingOrder />} />
          <Route path="/complete-order" element={<CompleteOrder />} />
          <Route path="/order" element={<HeaaderComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
